import React from 'react'
import Canvas from '../layouts/canvas'
import Header from '../layouts/header'
import Footer from '../layouts/footer'
import {Helmet} from 'react-helmet'
import {graphql} from 'gatsby'

import Breadcrumb from '../layouts/breadcrumb'

class Mainabout extends React.Component{

  constructor(props) {
    super(props)
  }

  render() {

    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen
    }
    else {
      menu = this.props.pageContext.menuel
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({url:item.entityUrl.path, id:item.entityLanguage.id})
    })
    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })

       return (
         <div>
           <Helmet>
            {this.props.pageContext.metainfo.map((item,i) => {
              if(item.key === 'title') {
                return (
                  [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
                )
              }
      

              else if(item.key === 'description') {
                return (
                  [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
                )
              }

              else {
                return null;
              }
            })}
            <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />
            <meta property="og:type" content="Website" />
            <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
            <meta property="og:image" content={`${process.env.FRONT_URL}/wd_logo.png`} />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:height" content="200" />
           </Helmet>

          <Canvas>
        <Header language={this.props.pageContext.langcode} menu={menu} langpath={langpath} pathname={this.props.path}/>
        <Breadcrumb title={this.props.pageContext.title} path={loc}/>
          <section className="s-pt-30 s-pt-lg-50 ls about ">
  				<div className="divider-60 d-none d-xl-block"></div>
  				<div className="container">
  					<div className="row">
  						<div className="col-md-8 offset-md-2">
                {this.props.data.drupal.pageabout.entities[0].body?
                   <div className="main-content text-center" dangerouslySetInnerHTML={{__html:this.props.data.drupal.pageabout.entities[0].body.processed}}>
                   </div>
                   :
                    null
                 }

  						</div>
  					</div>
  				</div>
  			</section>

  			<section className="s-pt-0  s-pb-30 s-pt-lg-30 s-pb-lg-75 ls about-icon teaser-contact-icon margin100 padding0">
  				<div className="divider-10 d-none d-xl-block"></div>
  				<div className="container">
  					<div className="row c-mt-50 c-mt-lg-0">
              {this.props.data.drupal.nodeQuery.entities.map((item,i) => {

                var iconclass=""
                if((i % 3 + 1) === 1) {
                  iconclass='call-icon'
                }
                else if((i % 3 + 1) === 2 ) {
                  iconclass = 'write-icon'
                }
                else {
                  iconclass = 'visit-icon'
                }
                return (
                  <div className={`col-lg-4 mb-2 text-center ${iconclass}`}>
                    <div className="border-icon">
                      <div dangerouslySetInnerHTML={{ __html: item.fieldIcon }} className="teaser-icon">

                      </div>
                    </div>
                    <h6>
                      {item.title}
                    </h6>
                    <div dangerouslySetInnerHTML={{ __html: item.body.processed }} className="icon-content">

                    </div>
                  </div>
                )
              })}



  					</div>
  				</div>
  			</section>

          <Footer/>

         </Canvas>
        </div>
       )

  }






}

export default Mainabout;

export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
    nodeQuery(filter: {conditions: [{field: "type", value: "about", language:$langcode},{field: "status", value: "1"}]}, sort: [{field: "nid", direction: ASC}]) {
     entities(language: $langcode) {
       ... on DRUPAL_NodeAbout {
         title
         body {
           processed
         }
         fieldIcon
       }
     }
   }

   pageabout: nodeQuery(filter: {conditions: [{field: "title", value: "about"},{field: "status", value: "1"}]}) {
     entities(language: $langcode) {
       ... on DRUPAL_NodePage {

         body {
           processed
         }
       }
     }
   }


   }
}
`
